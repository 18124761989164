import { buildAxiosRequestConfig } from '@b-stock/bstock-next'

import getAllListingsSearchClient from '@queries/getAllListingsSearchClient'

export type AllSellersData = {
  header: string
  items: {
    name: string
    href: string
    ariaLabel: string
  }[]
}[]

const getAllSellers = async (
  accessToken: string | null
): Promise<AllSellersData> => {
  const searchClient = getAllListingsSearchClient()
  const axiosParams = buildAxiosRequestConfig({ accessToken })
  const { data: sellers } =
    await searchClient.allListingsGetSellers(axiosParams)

  const topSellers = sellers.filter((seller) => seller.topSeller)

  return [
    {
      header: 'Top Sellers',
      items: topSellers.map((seller) => ({
        name: seller.name,
        href: seller.url,
        ariaLabel: seller.name,
      })),
    },
    {
      header: 'All Sellers',
      items: sellers.map((seller) => ({
        name: seller.name,
        href: seller.url,
        ariaLabel: seller.name,
      })),
    },
  ]
}

export default getAllSellers
