'use client'

import { styled } from 'styled-components'

import {
  Breakpoints,
  Colors,
  Shadows,
  Typography,
} from '@b-stock/bstock-react/design-system'

import { breakpointMinXLarge } from '@helpers/utils'

export const Nav = styled.nav`
  grid-area: nav;
  display: none;
  gap: 1rem;

  @media ${Breakpoints.min.large} {
    display: flex;
  }
`

export const Dropdown = styled.div<{
  $columns: number
  $fullWidth?: boolean
  $open?: boolean
}>`
  position: absolute;
  right: var(--headerPadding);
  ${({ $fullWidth }) => $fullWidth && 'left:  var(--headerPadding);'}
  display: grid;
  grid-template-columns: ${({ $columns }) => `repeat(${$columns}, 1fr)`};
  z-index: 1;
  padding: 1rem;
  ${Shadows.Hover};
  border-radius: 0.3125rem;
  background: ${Colors.Semantic.Neutral.white};
  opacity: ${({ $open }) => ($open ? 1 : 0)};
  visibility: ${({ $open }) => ($open ? 'visible' : 'hidden')};
  pointer-events: ${({ $open }) => ($open ? 'all' : 'none')};
  transition:
    opacity 0.3s ease-in-out,
    visibility 0.3s ease-in-out;
`

export const DropdownLinkContainer = styled.div``

export const DropdownTrigger = styled.div`
  ${Typography.Subtitle2}
  padding: 0.6875rem 0;
  position: relative;
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0.125rem;
    left: 0;
    bottom: 0;
    background-color: ${Colors.Semantic.BStock.default.lightBackground};
    transition: width 0.3s ease-in-out;
  }

  &:hover::after {
    width: 100%;
  }
`

export const DropdownItem = styled.a`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem 1.5rem;
  text-decoration: none;
  border-radius: 0.3125rem;
  transition: 0.3s ease;
  max-width: calc(25cqw - 2rem);

  &:hover {
    background: ${Colors.Semantic.Neutral.light_grey};
  }

  @media ${breakpointMinXLarge} {
    padding: 1rem 2rem;
  }
`

export const DDItemName = styled.p`
  ${Typography.Body1_Semibold}
  color: ${Colors.Semantic.Neutral.black};
`

export const DDItemDescription = styled.p`
  ${Typography.Body3}
  color: ${Colors.Semantic.Neutral.dark_grey};
`
