type AllCategoriesData = {
  name: string
  href: string
}[]

const allCategoriesData: AllCategoriesData = [
  {
    name: 'Apparel, Shoes & Accessories',
    href: '/all-auctions?categories=%5B"Apparel%20Shoes%20%26%20Accessories"%5D',
  },
  {
    name: 'Appliances',
    href: '/all-auctions?categories=%5B"Appliances"%5D',
  },
  {
    name: 'Automotive Supplies',
    href: '/all-auctions?categories=%5B"Automotive%20Supplies"%5D',
  },
  {
    name: 'Books, Movies & Music',
    href: '/all-auctions?categories=%5B"Books%20Movies%20%26%20Music"%5D',
  },
  {
    name: 'Building & Industrial',
    href: '/all-auctions?categories=%5B"Building%20%26%20Industrial"%5D',
  },
  {
    name: 'Cell Phones',
    href: '/all-auctions?categories=%5B"Cell%20Phones"%5D',
  },
  {
    name: 'Electronics',
    href: '/all-auctions?categories=%5B"Electronics"%5D',
  },
  {
    name: 'Furniture',
    href: '/all-auctions?categories=%5B"Furniture"%5D',
  },
  {
    name: 'Groceries',
    href: '/all-auctions?categories=%5B"Groceries"%5D',
  },
  {
    name: 'Health & Beauty',
    href: '/all-auctions?categories=%5B"Health%20%26%20Beauty"%5D',
  },
  {
    name: 'Home & Garden',
    href: '/all-auctions?categories=%5B"Home%20%26%20Garden"%5D',
  },
  {
    name: 'Jewelry & Watches',
    href: '/all-auctions?categories=%5B"Jewelry%20%26%20Watches"%5D',
  },
  {
    name: 'Mixed Lots',
    href: '/all-auctions?categories=%5B"Mixed%20Lots"%5D',
  },
  {
    name: 'Office Supplies & Equipment',
    href: '/all-auctions?categories=%5B"Office%20Supplies%20%26%20Equipment"%5D',
  },
  {
    name: 'Pets',
    href: '/all-auctions?categories=%5B"Pets"%5D',
  },
  {
    name: 'Sports & Outdoors',
    href: '/all-auctions?categories=%5B"Sports%20%26%20Outdoors"%5D',
  },
  {
    name: 'Toys, Kids & Baby',
    href: '/all-auctions?categories=%5B"Toys%20Kids%20%26%20Baby"%5D',
  },
]

export default allCategoriesData
