'use client'
import { Fragment } from 'react'

import regionsData from '../auctionNavData/regionsData'
import {
  RegionsContainer,
  Column,
  SubTitle,
  ItemList,
  Item,
  ItemLink,
  ItemLinkBold,
  Divider,
} from '../ui'

const RegionsLayout = () => (
  <RegionsContainer>
    {regionsData.map(({ header, items, footer }, index) => (
      <Fragment key={header}>
        {index > 0 && <Divider />}

        <Column>
          <SubTitle>{header}</SubTitle>

          <ItemList>
            {items.map((item) => (
              <Item key={item.name}>
                <ItemLink href={item.href}>{item.name}</ItemLink>
              </Item>
            ))}

            <Item>
              <ItemLinkBold href={footer.href}>{footer.name}</ItemLinkBold>
            </Item>
          </ItemList>
        </Column>
      </Fragment>
    ))}
  </RegionsContainer>
)

export default RegionsLayout
