import { Fragment, useEffect, useState } from 'react'

import getAllSellers from '@helpers/api/getAllSellers'
import type { AllSellersData } from '@helpers/api/getAllSellers'

import {
  SellersContainer,
  SubTitle,
  Item,
  ItemLink,
  AllSellersList,
  Column,
  ItemList,
  Divider,
} from '../ui'

const SellersLayout = () => {
  const [allSellersData, setAllSellersData] = useState<AllSellersData>([])

  useEffect(() => {
    const fetchData = async () => {
      const data = await getAllSellers(null)
      setAllSellersData(data)
    }

    void fetchData()
  }, [])

  return (
    <SellersContainer>
      {allSellersData.map(({ header, items }, index) => {
        const ListComponent = index === 0 ? ItemList : AllSellersList

        return (
          <Fragment key={header}>
            {index > 0 && <Divider />}
            <Column>
              <SubTitle>{header}</SubTitle>

              <ListComponent>
                {items.map((item) => (
                  <Item key={item.name}>
                    <ItemLink
                      href={item.href}
                      target="_blank"
                      rel="noopener"
                      aria-label={`View ${index === 0 ? 'top' : ''} seller ${item.ariaLabel} in a new tab`}
                    >
                      {item.name}
                    </ItemLink>
                  </Item>
                ))}
              </ListComponent>
            </Column>
          </Fragment>
        )
      })}
    </SellersContainer>
  )
}

export default SellersLayout
