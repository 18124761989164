'use client'

import { useRef, useState } from 'react'

import { preventOrphans } from '@helpers/utils'

import {
  Dropdown,
  Nav,
  DropdownTrigger,
  DropdownItem,
  DropdownLinkContainer,
  DDItemName,
  DDItemDescription,
} from './ui'
import navData from '../navData.json'

const dropdownItems = [
  {
    text: 'For Buyers',
    fillWidth: true,
    columns: 4,
    links: navData.forBuyer,
  },
  {
    text: 'For Sellers',
    fillWidth: true,
    columns: 4,
    links: navData.forSeller,
  },
  {
    text: 'Support',
    columns: 2,
    links: navData.support,
  },
]

export default function DropdownNavigation() {
  const [activeIndex, setActiveIndex] = useState<number | null>(null)
  const hideTimeoutRef = useRef<NodeJS.Timeout | null>(null)

  const handleMouseEnter = (index: number) => {
    if (hideTimeoutRef.current) {
      clearTimeout(hideTimeoutRef.current)
      hideTimeoutRef.current = null
    }
    setActiveIndex(index)
  }

  const handleMouseLeave = (index: number) => {
    hideTimeoutRef.current = setTimeout(() => {
      if (activeIndex === index) {
        setActiveIndex(null)
      }
      hideTimeoutRef.current = null
    }, 50)
  }

  return (
    <Nav>
      {dropdownItems.map((item, index) => (
        <DropdownLinkContainer
          key={item.text}
          onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={() => handleMouseLeave(index)}
        >
          <DropdownTrigger>{item.text}</DropdownTrigger>
          <Dropdown
            $columns={item.columns}
            $fullWidth={item.fillWidth}
            $open={activeIndex === index}
          >
            {item.links.map((link) => (
              <DropdownItem
                key={link.href}
                href={link.href}
                data-gtm-element-type="header subitem"
                data-gtm-element-text={link.name}
              >
                <DDItemName>{link.name}</DDItemName>
                <DDItemDescription>
                  {preventOrphans(link.description)}
                </DDItemDescription>
              </DropdownItem>
            ))}
          </Dropdown>
        </DropdownLinkContainer>
      ))}
    </Nav>
  )
}
