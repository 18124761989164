type AllConditionsData = {
  name: string
  href: string
}[]

const allConditionsData: AllConditionsData = [
  {
    name: 'New',
    href: '/all-auctions?condition=%5B"New"%5D',
  },
  {
    name: 'Like New',
    href: '/all-auctions?condition=%5B"Like%20New"%5D',
  },
  {
    name: 'Mixed Condition',
    href: '/all-auctions?condition=%5B"Mixed%20Condition"%5D',
  },
  {
    name: 'Refurbished',
    href: '/all-auctions?condition=%5B"Refurbished"%5D',
  },
  {
    name: 'Salvage',
    href: '/all-auctions?condition=%5B"Salvage"%5D',
  },
  {
    name: 'Scratch & Dent Appliances',
    href: '/all-auctions?condition=%5B"Scratch%20%26%20Dent%20Appliances"%5D',
  },
  {
    name: 'Used - Fair',
    href: '/all-auctions?condition=%5B"Used%20-%20Fair"%5D',
  },
  {
    name: 'Used - Good',
    href: '/all-auctions?condition=%5B"Used%20-%20Good"%5D',
  },
  {
    name: 'Used A',
    href: '/all-auctions?condition=%5B"Used%20A"%5D',
  },
  {
    name: 'Used B',
    href: '/all-auctions?condition=%5B"Used%20B"%5D',
  },
  {
    name: 'Used C',
    href: '/all-auctions?condition=%5B"Used%20C"%5D',
  },
  {
    name: 'Used D',
    href: '/all-auctions?condition=%5B"Used%20D"%5D',
  },
  {
    name: 'Used E',
    href: '/all-auctions?condition=%5B"Used%20E"%5D',
  },
]

export default allConditionsData
