'use client'

import { styled } from 'styled-components'

import { breakpointMinXLarge } from '@helpers/utils'

export const MobileUserActionsWrapper = styled.div`
  grid-area: userActions;

  button {
    padding: 0;
  }

  @media ${breakpointMinXLarge} {
    display: none;
  }
`

export const UserActionsWrapper = styled.div`
  grid-area: userActions;
  display: none;
  align-items: center;
  gap: 1rem;

  @media ${breakpointMinXLarge} {
    display: flex;
  }
`
