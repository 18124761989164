'use client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { styled, css } from 'styled-components'

import {
  Breakpoints,
  Colors,
  Shadows,
  Typography,
} from '@b-stock/bstock-react/design-system'

import { breakpointMinXLarge } from '@helpers/utils'

const noScrollbars = css`
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`

export const Nav = styled.nav`
  --submenuPadding: var(--headerPadding);
  --navHeight: 2.25rem;
  position: relative;
  width: 100%;
  max-width: calc(var(--maxPageWidth) + calc(var(--headerPadding) * 2));
  margin: 0 auto;
  height: var(--navHeight);

  @media ${Breakpoints.min.medium} {
    --submenuPadding: 2rem;
  }
`

export const Navbar = styled.div`
  display: flex;
  gap: 0.75rem;
  align-items: flex-start;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0 var(--headerPadding);
  ${noScrollbars}
  mask-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0) 0.5%,
      rgba(0, 0, 0, 1) var(--headerPadding),
      rgba(0, 0, 0, 1) calc(100% - var(--headerPadding)),
      rgba(0, 0, 0, 0) 99.5%
    ), linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) var(--navHeight),
      rgba(0, 0, 0, 1) var(--navHeight)
    );
  mask-size:
    100% var(--navHeight),
    100%;
  mask-repeat: no-repeat, repeat;

  &:has(li:hover) {
    height: calc(28rem + var(--navHeight));
  }
`

export const Divider = styled.span`
  display: block;
  min-width: 0.0625rem;
  align-self: stretch;
  background-color: ${Colors.Semantic.Neutral.medium_grey};
`

export const AuctionNavDivider = styled(Divider)<{ $useFullHeight?: boolean }>`
  ${({ $useFullHeight }) =>
    $useFullHeight
      ? css`
          height: var(--navHeight);
        `
      : css`
          margin: 0.5rem 0;
          height: calc(var(--navHeight) - 1rem);
        `}
`

export const NavbarUl = styled.ul`
  display: flex;
  gap: 0.75rem;
`

export const NavbarLink = styled.a<{ href?: string }>`
  position: relative;
  ${Typography.Body3};
  color: ${Colors.Semantic.Neutral.black};
  line-height: 2.25rem;
  text-decoration: none;
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0.125rem;
    left: 0;
    bottom: -0.25rem;
    background-color: ${Colors.Semantic.BStock.default.lightBackground};
    transition: width 0.3s ease-in-out;
  }

  &:hover::after {
    width: 100%;
  }
`

export const NavSubmenuIcon = styled(FontAwesomeIcon)`
  width: 0.625rem;
  height: 0.625rem;
`

export const SubmenuContainer = styled.div`
  position: absolute;
  top: calc(100% + 0.25rem + 0.0625rem);
  left: 0;
  z-index: 1000;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  background: ${Colors.Semantic.Neutral.white};
  ${Shadows.Cards};
  padding: 1.5rem 0;
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 0.3s linear,
    visibility 0s 0.3s;
`

export const NavbarLi = styled.li`
  &:hover {
    padding-bottom: calc(0.25rem + 0.0625rem);
  }

  &:hover ${SubmenuContainer} {
    opacity: 1;
    visibility: visible;
    transition:
      opacity 0.3s linear,
      visibility 0s 0s;
  }

  &:hover ${NavbarLink}::after {
    width: 100%;
  }
`

const scrollArea = (maxHeight: number) => css`
  --scrollbarSpace: 1.5rem;
  --scrollbarHeight: 0.25rem;
  overflow: auto;
  max-height: calc(
    ${maxHeight}rem + var(--scrollbarSpace) + var(--scrollbarHeight)
  );
  padding: 0 var(--submenuPadding) var(--scrollbarSpace);
  mask-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0.5%,
    rgba(0, 0, 0, 1) var(--submenuPadding),
    rgba(0, 0, 0, 1) calc(100% - var(--submenuPadding)),
    rgba(0, 0, 0, 0) 99.5%
  );

  &::-webkit-scrollbar {
    height: var(--scrollbarHeight);
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(120, 130, 138, 0.7);
    border-radius: 0.125rem;
    border: solid ${Colors.Semantic.Neutral.white};
    border-width: 0 0.0625rem;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(45deg, #4a6bff, #7a7ff0);
  }

  &::-webkit-scrollbar-track {
    background: rgba(20, 26, 30, 0.1);
    border-radius: 0.125rem;
    margin: 0 var(--submenuPadding);
  }
`

export const ItemList = styled.ul`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  row-gap: 0.5rem;
  column-gap: 3rem;
`

type ScrollItemList = {
  $maxHeight?: number
}

export const ScrollItemList = styled(ItemList)<ScrollItemList>`
  ${({ $maxHeight }) => scrollArea($maxHeight || 19.5)}

  @media ${Breakpoints.min.medium} {
    padding-bottom: 0;
  }
`

export const PaddedItemList = styled(ItemList)`
  padding: 0 var(--submenuPadding);
`

export const Item = styled.li`
  min-width: 8rem;
`

export const SubTitle = styled.h6`
  ${Typography.Overline1}
  color: ${Colors.Semantic.Neutral.very_dark_grey};
  text-transform: uppercase;
  letter-spacing: 0.07rem;
  white-space: nowrap;
`

export const ItemLink = styled.a`
  ${Typography.Body2}
  color: ${Colors.Semantic.Neutral.light_black};
  text-decoration: none;
  white-space: nowrap;

  &:hover {
    text-decoration: none;
    color: ${Colors.Semantic.BStock.default.lightBackground};
  }
`

export const ItemLinkBold = styled(ItemLink)`
  ${Typography.Body2Semibold}
`

export const CategoriesContainer = styled.div``

export const ConditionsContainer = styled.div``

export const SellersContainer = styled.div`
  display: flex;
  gap: 3rem;
  ${scrollArea(21.875)}
  min-width: 99vw;
  @media ${Breakpoints.min.medium} {
    max-height: unset;
    mask-image: none;
    padding-bottom: 0;
  }
  @media ${breakpointMinXLarge} {
    min-width: 78.75rem;
  }
`

export const RegionsContainer = styled.div`
  display: flex;
  gap: 3rem;
  ${scrollArea(14)}

  @media ${Breakpoints.min.medium} {
    padding-bottom: 0;
  }
`

export const TopSellersList = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  max-width: 12.5rem;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export const AllSellersList = styled(ItemList)`
  max-height: 19.5rem;

  @media ${Breakpoints.min.medium} {
    ${scrollArea(19.5)}
    margin: 0 calc(var(--submenuPadding) * -1);
  }
`
