import {
  useAuthState,
  useLogout,
  useAccessTokenRoles,
} from '@b-stock/bstock-next'
import { PortalName, UserMenu } from '@b-stock/bstock-react'

import { MobileUserActionsWrapper } from './ui'

export default function MobileUserActions() {
  const authState = useAuthState()
  const accountRoles = useAccessTokenRoles()
  const logout = useLogout()
  const baseUrl = 'https://bstock.com/'
  const person = authState?.person
  const portalName =
    accountRoles?.buyer || accountRoles?.service
      ? PortalName.BUYER
      : PortalName.SELLER
  const accountId = person?.accountId

  return (
    <MobileUserActionsWrapper>
      <UserMenu
        baseUrl={baseUrl}
        accountId={accountId}
        personId={person?._id}
        firstName={person?.firstName}
        lastName={person?.lastName}
        portalName={portalName}
        onLogout={logout}
        showMenuIcon={false}
        slideOut="right"
      />
    </MobileUserActionsWrapper>
  )
}
