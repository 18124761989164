'use client'

import {
  faFacebookF,
  faXTwitter,
  faLinkedinIn,
} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Image from 'next/image'
import Link from 'next/link'
import { styled } from 'styled-components'

import {
  Breakpoints,
  Colors,
  Typography,
} from '@b-stock/bstock-react/design-system'

const year = new Date().getFullYear()
const FooterWrapper = styled.footer`
  --paddingX: 1.5rem;

  ${Typography.Body2}
  color: ${Colors.Semantic.Neutral.black};
  border-top: 0.0625rem solid ${Colors.Semantic.Neutral.medium_grey};
  padding: 3rem 0 1.5rem;
  width: calc(100% - calc(var(--paddingX) * 2));
  max-width: var(--maxPageWidth);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 3rem;

  @media ${Breakpoints.min.large} {
    --paddingX: 3rem;
    padding: 4rem 0 2.5rem;
  }
`

const FooterNav = styled.nav`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem 0;

  @media ${Breakpoints.min.medium} {
    gap: 1.5rem 0;
  }

  @media ${Breakpoints.min.large} {
    gap: 4rem;
    flex-wrap: nowrap;
  }
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  min-width: fit-content;
  flex-basis: 50%;

  @media ${Breakpoints.min.medium} {
    flex-basis: 25%;
  }

  @media ${Breakpoints.min.large} {
    flex-basis: auto;
  }
`

const LogoColumn = styled(Column)`
  flex-grow: 1;
  margin-bottom: 1rem;
  flex-basis: 100%;
  width: auto;

  @media ${Breakpoints.min.medium} {
    flex-basis: 100%;
  }

  @media ${Breakpoints.min.large} {
    flex-basis: auto;
    margin-bottom: 2rem;
  }
`

const ColumnTitle = styled.h3`
  ${Typography.Subtitle1}
`

const LinkList = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

const LinkItem = styled.li`
  transition: color 0.3s ease;

  &:hover {
    color: ${Colors.Semantic.BStock.onhover};
  }
`

const BottomSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
`

const SocialIcons = styled.div`
  display: flex;
  gap: 1rem;

  a {
    padding: 0.5rem;
  }

  @media ${Breakpoints.min.medium} {
    gap: 2rem;

    a {
      padding: 0;
    }
  }
`

const SocialIcon = styled(FontAwesomeIcon)`
  display: block;
  font-size: 1rem;
  color: ${Colors.Semantic.BStock.default.lightBackground};
  transition: color 0.3s ease;

  &:hover {
    color: ${Colors.Semantic.BStock.onhover};
  }

  @media ${Breakpoints.min.medium} {
    font-size: 1.5rem;
  }
`

const Copyright = styled.div`
  @media ${Breakpoints.max.medium} {
    font-size: 0.875rem;
  }
`

const LogoImage = styled(Image)`
  width: 6.25rem;
  height: 1.5625rem;

  object-fit: contain;
  object-position: left top;

  @media ${Breakpoints.min.medium} {
    width: 9rem;
    height: 2.25rem;
  }
`

const nav = [
  {
    title: 'Support',
    links: [
      {
        href: '/contact-us/',
        text: 'Contact Us',
      },
      {
        href: '/terms-of-use/',
        text: 'Terms of Use',
      },
      {
        href: '/privacy-policy/',
        text: 'Privacy Policy',
      },
      {
        href: '/faq/',
        text: 'FAQs',
      },
    ],
  },
  {
    title: 'Company',
    links: [
      {
        href: '/about-us/',
        text: 'About Us',
      },
      {
        href: '/careers/',
        text: 'Careers',
      },
      {
        href: '/press-media/',
        text: 'Press & Media',
      },
    ],
  },
  {
    title: 'For Buyers',
    links: [
      {
        href: '/buyer-resource-center/',
        text: 'How to Buy',
      },
      {
        href: '/blog/category/buyer/',
        text: 'Buyer Blog',
      },
    ],
  },
  {
    title: 'For Sellers',
    links: [
      {
        href: '/how-to-sell/',
        text: 'How to Sell',
      },
      {
        href: '/blog/category/seller/',
        text: 'Seller Blog',
      },
    ],
  },
]

const Footer = () => {
  return (
    <FooterWrapper data-gtm-event-position="footer">
      <FooterNav data-gtm-element-type="footer item">
        <LogoColumn>
          <Link href="/">
            <LogoImage
              src="/home-portal/images/bstock_logo.svg"
              alt="B-Stock Solutions"
              width={144}
              height={36}
            />
          </Link>
        </LogoColumn>

        {nav.map(({ title, links }) => (
          <Column key={title}>
            <ColumnTitle>{title}</ColumnTitle>
            <LinkList>
              {links.map(({ href, text }) => (
                <LinkItem key={text}>
                  <Link href={href}>{text}</Link>
                </LinkItem>
              ))}
            </LinkList>
          </Column>
        ))}
      </FooterNav>

      <BottomSection>
        <SocialIcons data-gtm-element-type="footer social">
          <Link
            href="https://www.facebook.com/bstocksolutions"
            aria-label="Facebook"
            data-gtm-element-text="Facebook"
          >
            <SocialIcon icon={faFacebookF} />
          </Link>
          <Link
            href="https://x.com/bstocksol"
            aria-label="X"
            data-gtm-element-text="X"
          >
            <SocialIcon icon={faXTwitter} />
          </Link>
          <Link
            href="https://linkedin.com/company/bstocksolutions"
            aria-label="LinkedIn"
            data-gtm-element-text="LinkedIn"
          >
            <SocialIcon icon={faLinkedinIn} />
          </Link>
        </SocialIcons>
        <Copyright>&copy; {year} B-Stock Solutions, LLC</Copyright>
      </BottomSection>
    </FooterWrapper>
  )
}

export default Footer
