'use client'

import { styled } from 'styled-components'

const ModalPortal = styled.div.attrs({ id: 'modal-portal-wrapper' })`
  position: relative;
  z-index: 9999;
`

export default ModalPortal
