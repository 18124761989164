'use client'
import { useCallback, useEffect } from 'react'

import { faSearch } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Script from 'next/script'

import { SearchWidgetContainer, StyledInput, SearchIconButton } from './ui'

export default function SearchWidget() {
  const injectStyles = () => {
    const widget = document.querySelector('gen-search-widget')
    const shadowRoot1 = widget?.shadowRoot

    if (!shadowRoot1) return // Early return if shadowRoot is not found

    const outerDiv = shadowRoot1.querySelector(
      'div[tabindex="0"][role="dialog"][aria-label="Search widget"]'
    )

    if (outerDiv) {
      // Apply multiple styles to the outerDiv
      const outerDivStyle = document.createElement('style')
      outerDivStyle.textContent = `
        div[tabindex="0"][role="dialog"][aria-label="Search widget"] {
          display: flex !important;
          flex-direction: column !important;
          width: 100% !important;
          height: 100vh !important;
          top: 51% !important;
          left: 50% !important;
          margin-right: -50% !important;
          transform: translate(-50%, -50%) !important;
          padding: 0.9375rem !important;
          backdrop-filter: blur(2px) !important;
          background: rgba(255, 255, 255, .4) !important;
        }
      `
      shadowRoot1.appendChild(outerDivStyle)

      // Styling the backdrop and its contents
      const backdrop = outerDiv.querySelector('.backdrop')
      if (backdrop) {
        const backdropStyle = document.createElement('style')
        backdropStyle.textContent = `
          .backdrop > md-icon-button {
            background: #f6f7fa!important;
            border-radius: 50%!important;
            box-shadow: 0 0.125rem 0.3125rem 0.0625rem rgba(64, 60, 67, .16)!important;
          }
            .backdrop button {
            color: #424a58!important;
            font-weight: 700!important;
            }
          .backdrop md-icon-button md-icon {
            color: #424a58!important;
            font-weight: 700!important;
          }
        `
        backdrop.appendChild(backdropStyle)
      }

      // Accessing the shadow DOM within .content and styling the .results-list
      const content = outerDiv.querySelector('.content')
      const ucsResults = content?.querySelector('ucs-results')

      if (ucsResults) {
        const ucsResultsStyle = document.createElement('style')
        ucsResultsStyle.textContent = `
        .search-toolbar > .filters-container {
          display: flex!important;
          flex-wrap: wrap!important;
          gap: 0.5rem!important;
        }
      `
        ucsResults.appendChild(ucsResultsStyle)

        if (ucsResults.shadowRoot) {
          const scroller = ucsResults.shadowRoot.querySelector('.scroller')
          const resultsList = scroller?.querySelector('.results-list')

          if (resultsList) {
            const resultsListStyle = document.createElement('style')
            resultsListStyle.textContent = `
            .results-list {
              padding: 0.75rem clamp(1.25rem,12dvw,10rem)!important;
            }
            .results-list > .item {
              max-width: 100%!important;
            }
            .results-list > .item > .rows > .header {
              font-size: 1.125rem!important;
            }
          `
            resultsList.appendChild(resultsListStyle)
          }
        }
      }
    }
  }

  const setupWidgetInteraction = useCallback(() => {
    const widget = document.querySelector('gen-search-widget')
    if (widget) {
      // Reapply styles whenever the widget is clicked or interacted with
      widget.addEventListener('click', injectStyles)
      widget.addEventListener('focus', injectStyles)
      widget.addEventListener('open', injectStyles)

      injectStyles()
    }
  }, [])

  useEffect(() => {
    window.addEventListener('load', setupWidgetInteraction)
    return () => {
      window.removeEventListener('load', setupWidgetInteraction)
    }
  }, [setupWidgetInteraction])

  return (
    <SearchWidgetContainer
      id="searchWidgetContainer"
      data-testid="legacy-search-widget"
    >
      <Script
        src="https://cloud.google.com/ai/gen-app-builder/client?hl=en_US"
        strategy="afterInteractive"
        onLoad={() => {
          setupWidgetInteraction()
        }}
      />
      <gen-search-widget
        configId="483df4f7-e2fb-4de3-8177-a4a7359b2a84"
        triggerId="searchWidgetTrigger"
      />
      <StyledInput id="searchWidgetTrigger">
        Search for auctions by category, product, seller&hellip;
      </StyledInput>
      <SearchIconButton
        onClick={() => document.getElementById('searchWidgetTrigger')?.click()}
      >
        <FontAwesomeIcon id="searchIcon" icon={faSearch} />
      </SearchIconButton>
    </SearchWidgetContainer>
  )
}
