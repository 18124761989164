'use client'

import { styled } from 'styled-components'

import { Breakpoints, Colors } from '@b-stock/bstock-react/design-system'

import HeaderAuctionNavigation from './headerAuctionNavigation/HeaderAuctionNavigation'
import HeaderPrimaryNavigation from './headerPrimaryNavigation/HeaderPrimaryNavigation'

const HeaderContainer = styled.header`
  --headerPadding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: relative;
  z-index: 1000;
  padding: 1.5rem 0 0.25rem;
  border-bottom: 0.0625rem solid ${Colors.Semantic.Neutral.medium_grey};

  @media ${Breakpoints.min.medium} {
    --headerPadding: 3rem;
    gap: 1.5rem;
  }

  @media ${Breakpoints.max.medium} {
    z-index: 25;
  }
`

export default function Header() {
  return (
    <HeaderContainer>
      <HeaderPrimaryNavigation />
      <HeaderAuctionNavigation />
    </HeaderContainer>
  )
}
