import {
  CATEGORIES,
  CONDITIONS,
  REGIONS,
  SELLERS,
  type Submenu,
} from '../auctionNavSubMenu/SubMenu'

export type AuctionNavData = {
  key: Submenu
  items: {
    href?: string
    name: string
  }[]
}[]

const auctionNavData: AuctionNavData = [
  {
    key: CATEGORIES,
    items: [
      {
        name: 'Appliances',
        href: '/all-auctions?categories=%5B"Appliances"%5D',
      },
      {
        name: 'Cell Phones',
        href: '/all-auctions?categories=%5B"Cell%20Phones"%5D',
      },
      {
        name: 'All Categories',
      },
    ],
  },
  {
    key: SELLERS,
    items: [
      {
        name: 'Amazon',
        href: '/amazon/',
      },
      {
        name: 'Samsung',
        href: '/samsung/',
      },
      {
        name: 'All Sellers',
      },
    ],
  },
  {
    key: CONDITIONS,
    items: [
      {
        name: 'Like New',
        href: '/all-auctions?condition=%5B"Like%20New"%5D',
      },
      {
        name: 'Mixed',
        href: '/all-auctions?condition=%5B"Mixed%20Condition"%5D',
      },
      {
        name: 'All Conditions',
      },
    ],
  },
  {
    key: REGIONS,
    items: [
      {
        name: 'Northeast',
        href: '/all-auctions?region=%5B"Northeast"%5D',
      },
      {
        name: 'Midwest',
        href: '/all-auctions?region=%5B"Midwest"%5D',
      },
      {
        name: 'South',
        href: '/all-auctions?region=%5B"South"%5D',
      },
      {
        name: 'West',
        href: '/all-auctions?region=%5B"West"%5D',
      },
      {
        name: 'All Regions',
      },
    ],
  },
]

export default auctionNavData
