'use client'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { styled } from 'styled-components'

import {
  Breakpoints,
  Colors,
  Typography,
} from '@b-stock/bstock-react/design-system'

export const StyledFAIcon = styled(FontAwesomeIcon)<{ $size?: string }>`
  color: inherit;
  font-size: ${({ $size }) => $size || '1rem'};
`

export const HeaderDiv = styled.div`
  position: relative;
  width: 100%;
  max-width: calc(var(--maxPageWidth) + calc(var(--headerPadding) * 2));
  margin: 0 auto;
  padding: 0.5rem var(--headerPadding);
  display: grid;
  grid-template-columns: auto auto 1fr auto;
  grid-template-areas: 'navtrigger navbrand searchwidget userActions';
  gap: 1rem;
  align-items: center;
  background: ${Colors.Semantic.Neutral.white};

  @media ${Breakpoints.min.medium} {
    padding: 0 var(--headerPadding);
  }

  @media ${Breakpoints.min.large} {
    grid-template-columns: auto 1fr auto auto;
    grid-template-areas: 'navbrand searchwidget nav userActions';
  }
`

export const MobileNavTrigger = styled.button`
  grid-area: navtrigger;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  @media ${Breakpoints.min.large} {
    display: none;
  }
`

export const NavBrand = styled.a`
  grid-area: navbrand;

  img {
    width: 9rem;
    height: 2.25rem;
  }
`

export const SearchWidgetContainer = styled.div`
  grid-area: searchwidget;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  margin: 0 0.5rem;

  @media ${Breakpoints.min.large} {
    margin-right: 2rem;
  }
`

export const StyledInput = styled.div`
  display: none;
  color: ${Colors.Semantic.Neutral.dark_grey};
  padding: 0.75rem;
  border: 0.0625rem solid ${Colors.Semantic.Neutral.medium_grey};
  border-radius: 0.25rem;
  width: 100%;
  ${Typography.Body2};

  @media ${Breakpoints.min.medium} {
    display: block;
  }
`

export const SearchIconButton = styled.button`
  svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  @media ${Breakpoints.min.medium} {
    position: absolute;
    top: 0.44rem;
    right: 0.75rem;
    padding: 0.75rem;

    svg {
      width: 0.75rem;
      height: 0.75rem;
    }
  }
`
