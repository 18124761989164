'use client'

import CategoriesLayout from './CategoriesLayout'
import ConditionsLayout from './ConditionsLayout'
import RegionsLayout from './RegionsLayout'
import SellersLayout from './SellersLayout'
import { SubmenuContainer } from '../ui'

export const CATEGORIES = 'categories'
export const SELLERS = 'sellers'
export const CONDITIONS = 'conditions'
export const REGIONS = 'regions'

export const submenus = [CATEGORIES, SELLERS, CONDITIONS, REGIONS] as const
export type Submenu = (typeof submenus)[number]

type SubMenu = {
  submenu: Submenu
}

const SubMenu = ({ submenu }: SubMenu) => {
  return (
    <SubmenuContainer>
      {submenu === CATEGORIES && <CategoriesLayout />}
      {submenu === CONDITIONS && <ConditionsLayout />}
      {submenu === REGIONS && <RegionsLayout />}
      {submenu === SELLERS && <SellersLayout />}
    </SubmenuContainer>
  )
}

export default SubMenu
