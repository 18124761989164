import { pxToRem } from '@b-stock/bstock-react/design-system'

// a custom breakpoint for home portal header
export const breakpointMinXLarge = `(min-width: ${pxToRem(1180)})`

export const preventOrphans = (string: string, minChildren = 2) => {
  const words = string.split(' ')

  if (words.length > minChildren) {
    const endSegment = words.splice(-minChildren)
    return [...words, endSegment.join('\u00A0')].join(' ')
  }

  return string
}
