import {
  useAuthState,
  useLogout,
  useAccessTokenRoles,
} from '@b-stock/bstock-next'
import { UserMenu, PortalName, Button } from '@b-stock/bstock-react'

import { redirectSignUp, redirectToAuth } from '@helpers/auth'
import { useAnalytics } from '@helpers/telemetry/SegmentAnalytics'

import { UserActionsWrapper } from './ui'

export default function UserActions() {
  const { trackButtonClicked } = useAnalytics()
  const authState = useAuthState()
  const accountRoles = useAccessTokenRoles()
  const logout = useLogout()

  const isLoggedIn = !!authState?.person

  const baseUrl = 'https://bstock.com/'
  const registerHref = '/acct/signup'
  const signInHref = '/acct/signin'
  const person = authState?.person
  const portalName =
    accountRoles?.buyer || accountRoles?.service
      ? PortalName.BUYER
      : PortalName.SELLER
  const accountId = person?.accountId

  const handleSignIn = () => {
    redirectToAuth()
    // TODO: Make the entity_type dynamic based on the signed-in user's role
    trackButtonClicked('homepage', 'sign_in', 'home_portal', 'buyer', {
      url: document.URL,
      referrer: document.referrer,
      cta_url: signInHref,
    })
  }

  const handleClickRegister = () => {
    redirectSignUp()
    // TODO: Make the entity_type dynamic based on the signed-in user's role
    trackButtonClicked('homepage', 'register_now', 'home_portal', 'buyer', {
      url: document.URL,
      referrer: document.referrer,
      cta_url: registerHref,
    })
  }

  return (
    <UserActionsWrapper>
      {!isLoggedIn ? (
        <>
          <Button appearance="secondary" size="medium" onClick={handleSignIn}>
            Sign In
          </Button>
          <Button
            appearance="primary"
            size="medium"
            onClick={handleClickRegister}
          >
            Register Now
          </Button>
        </>
      ) : (
        <UserMenu
          baseUrl={baseUrl}
          accountId={accountId}
          personId={person?._id}
          firstName={person?.firstName}
          lastName={person?.lastName}
          portalName={portalName}
          onLogout={logout}
          showMenuIcon={false}
        />
      )}
    </UserActionsWrapper>
  )
}
