type Item = {
  name: string
  href: string
}

type AllRegionsData = {
  header: string
  items: Item[]
  footer: Item
}[]

const allRegionsData: AllRegionsData = [
  {
    header: 'United States',
    items: [
      {
        name: 'Northeast',
        href: '/all-auctions?region=%5B"Northeast"%5D',
      },
      {
        name: 'Midwest',
        href: '/all-auctions?region=%5B"Midwest"%5D',
      },
      {
        name: 'South',
        href: '/all-auctions?region=%5B"South"%5D',
      },
      {
        name: 'West',
        href: '/all-auctions?region=%5B"West"%5D',
      },
    ],
    footer: {
      name: 'Shop All US',
      href: '/all-auctions?region=%5B"United%20States"%5D',
    },
  },
  {
    header: 'Canada',
    items: [
      {
        name: 'Alberta',
        href: '/all-auctions?region=%5B"Alberta"%5D',
      },
      {
        name: 'British Columbia',
        href: '/all-auctions?region=%5B"British%20Columbia"%5D',
      },
      {
        name: 'Quebec',
        href: '/all-auctions?region=%5B"Quebec"%5D',
      },
      {
        name: 'Ontario',
        href: '/all-auctions?region=%5B"Ontario"%5D',
      },
    ],
    footer: {
      name: 'Shop All CANADA',
      href: '/all-auctions?region=%5B"Canada"%5D',
    },
  },
  {
    header: 'Europe',
    items: [
      {
        name: 'France',
        href: '/all-auctions?region=%5B"France"%5D',
      },
      {
        name: 'Germany',
        href: '/all-auctions?region=%5B"Germany"%5D',
      },
      {
        name: 'Poland',
        href: '/all-auctions?region=%5B"Poland"%5D',
      },
      {
        name: 'Spain',
        href: '/all-auctions?region=%5B"Spain"%5D',
      },
      {
        name: 'United Kingdom',
        href: '/all-auctions?region=%5B"United%20Kingdom"%5D',
      },
    ],
    footer: {
      name: 'Shop All EUROPE',
      href: '/all-auctions?region=%5B"Europe"%5D',
    },
  },
]

export default allRegionsData
