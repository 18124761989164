'use client'
import Link from 'next/link'

import conditionsData from '../auctionNavData/conditionsData'
import { ConditionsContainer, Item, ItemLink, ScrollItemList } from '../ui'

const ConditionsLayout = () => {
  return (
    <ConditionsContainer>
      <ScrollItemList $maxHeight={14}>
        {conditionsData.map((item) => (
          <Item key={item.name}>
            <ItemLink href={item.href} as={Link}>
              {item.name}
            </ItemLink>
          </Item>
        ))}
      </ScrollItemList>
    </ConditionsContainer>
  )
}

export default ConditionsLayout
